<template>
    <div class="h-100 d-flex flex-column hideme">
        <img
            :src="getImgUrl(data.image)"
            class="m-auto w-100"
            style="max-width: 200px"
            v-bind:alt="data.image"
        />
        <h5 class="mx-auto mt-2 text-center font-weight-bolder h3 text-dark" style="color:#000">{{data.title}}</h5>
    </div>
</template>

<script>
export default {
    props : {
        data: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        getImgUrl(pic) {
            return require('../../static/assets/'+pic)
        }
    }
}
</script>