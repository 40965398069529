<template>
  <Layout navbarAbsolute navbarTransparent> 
    <div style="" class="ql-editor bg-home">
      <div class="container d-flex" style="min-height:100vh">
        <div class="my-auto mr-auto" style="max-width:auto;">
          <div class="mb-1" id="LAP-1">Lorem Ipsum Lorem Ipsum Lorem Ipsum</div> 
        </div>
      </div>
    </div>
    <div class="container" style="padding:0 10%">
      <div style="background-color: #F6F6F6" class="">
      <div class="pt-4 ">
        <div class="position-relative"> 
          <div class="container position-relative bg-d" style="z-index:0;margin-top:-12em">
            <div class="row align-items-center justify-content-center my-5 py-0" style="padding: 0 10%;gap:10%">
              <div class="col-lg-auto my-1 justify-content-center d-flex">
                <img src="@/static/logo-bg-home.png" class=" w-100" style="max-width:300px;border-radius:30px;" />
              </div>
              <div class="col-lg my-2 text-white ql-editor ">
                <div id="LAP-2">
                  The world changes. Change forces lifestyle and behavior to adapt. The
                  old ways of working are no longer relevant. The management style is
                  constantly evolving leading to agility. The use of technology is a must.
                  We need to improve it right now!
                </div> 
              </div>
            </div>
          </div>

          <div class="position-relative bg-light">
            <div class="container py-5 ql-editor">
              <div class="d-flex flex-column">
                <div class="hideme" id="LAP-3">
                  What You Need
                </div>
                <img src="@/static/assets/Group 676.png" class="mx-auto mb-5" />
              </div>
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-auto d-flex hideme">
                  <img src="@/static/assets/home/Group 679.png" class="w-100 mr-auto" style="max-width:500px" />
                </div>
                <div class="col-lg-4 hideme"> 
                  <div id="LAP-4">
                    The most important part of an organization is people. Transformation
                    of people and organization, including management systems, is needed to
                    be able to win in the midst of rapid changes that occur.
                  </div>
                </div>
              </div>

              <div class="row align-items-center mt-3 justify-content-center">
                <div class="col-lg-4 align-items-center hideme"> 
                  <div id="LAP-5">
                    It is almost impossible to excel in today's era without being at the
                    forefront of using technology as part of who we are. Technological
                    developments are what you really need.
                  </div>
                </div>
                <div class="col-lg-auto d-flex justify-content-end hideme ">
                  <img src="@/static/assets/home/Group 678.png" class="w-100 ml-auto" style="max-width:auto" />
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <Servicetron>
              <div id="LAP-6">
                HUMANTECHNO specifically helps you to leverage people and technology in
                your organization to drive business performance.
              </div> 
            </Servicetron>
          </div>

          <img src="@/static/assets/contacts/item2.png" class="position-absolute" style=";right:0;transform:rotate(180deg)" />

          <div class="container ql-editor">
            <div class="d-flex flex-column">
              <div class="hideme" id="LAP-7">
                  What You Need
              </div>
              <img src="@/static/assets/Group 676.png" class="mx-auto mb-5" />
            </div>

            <div class="row" style="">
              <div class="col-lg-4 col-md-6 p-3 w-100" v-for="(data,idx) in services" :key="idx+'SERVICES'">
                <ServiceCard :data="data" />
              </div> 
            </div> 

            <Servicetron>
              <div id="LAP-8" class="text-white">DON'T WAIT TO TRANSFORM! LET US HELP YOU</div>
              <router-link to="/contacts" style="">
                <div
                  class="mt-1 mx-auto p-1 px-2 rounded-pill shadow fab-help"
                  style="max-width: fit-content;"
                >
                  <span id="LAP-9">CONTACT US</span>
                </div>
              </router-link>
            </Servicetron>
            
          </div>
        </div>
      </div>
    </div> 
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";

import ServiceCard from "@/components/home/ServiceCard.vue"; 
import Servicetron from "@/components/home/Servicetron.vue"; 

import store from '@/store'

export default {
  components: {
    Layout, ServiceCard, Servicetron
  },
  methods: {
    async getNextDatas(){
      let temp = await store.dispatch(`page_content/getDataList`, {
          lang_code: this.currentLocale,
          code: 'LND',  
      }) 
      temp?.map((item, key) => {
        document.getElementById(item.code).innerHTML = item.content
      })
      this.$forceUpdate()
    },
  },
  mounted(){
      this.getNextDatas()
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT
    },
  },
  watch: {
    currentLocale(val) {
      this.getNextDatas();
    },
  },
  data(){ 
    return {
      page_content : {

      },
      services: [
        {
          image: 'home/item5.png',
          title: 'Bussiness Modelling'
        },
        {
          image: 'home/item6.png',
          title: 'Human Capital'
        },
        {
          image: 'home/item7.png',
          title: 'Quality Management'
        },
        {
          image: 'home/item8.png',
          title: 'Organization Development'
        },
        {
          image: 'home/item9.png',
          title: 'Digitalization'
        },
        {
          image: 'home/item10.png',
          title: 'Change Management'
        },
      ]
    }
  }
};
</script>

<style>
.mask1 {
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 40%);
  mask-image: linear-gradient(to top, transparent 0%, black 90%);
}
.bg-d {
  border-radius: 20px;
  background-color: #0b5087;
  background-image: url('../static/assets/home/cscs 2.png');
} 
.bg-home {
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 40%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 0%);
  background-image:url('../static/assets/Group 704.png'); 
  background-repeat: no-repeat;
  background-size: cover;
}

</style>