<template>
  <div>
    <div
      class="p-2 py-3 text-center my-4 text-white bg"
      style=" 
      "
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bg {
    border-radius: 20px;
    background-color: #0b5087;
    background-image: url('../../static/assets/home/cscs 2.png');
}
</style>